$(".cart-toggle").on("click", function () {
    $(".cart-dropdown").toggleClass("show");
});

$(document).on("click", ".cart-close", function (e) {
	e.preventDefault();
	e.stopPropagation();
    $(".cart-dropdown").removeClass("show");
});

$(document).on('click', function () {
	$(".cart-dropdown").removeClass("show");
});

$(".cart-toggle").on('click', function (e) {
	e.stopPropagation();
});
