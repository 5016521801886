import "../_plugins/@master/css";
import "./partials/Header";
import "./partials/Slider";
import "./partials/ProductDetail";
import "./partials/Toggle";
import "./partials/ExpandContent";
import "./partials/About";
import "./partials/Landing";
import "./partials/TabCategory";
import "./partials/MoveDom";
import "./partials/Accordion";

var lazyLoadInstance = new LazyLoad({
	// Your custom settings go here
});

window.lazyLoadInstance = lazyLoadInstance;

jQuery(function () {
	addFirstColumnClass();
	$(window).resizable({
		resizeend: function () {
			addFirstColumnClass();
		},
	});
	$(".select-change-link").on("change", function () {
		var url = $(this).val(); // Get the URL from the selected option
		if (url) {
			// If a URL was selected
			window.location = url; // Go to the URL
		}
	});

	$(".anchor-link").on("click", function (e) {
		e.preventDefault();
		scrollToElement(
			$(this).attr("href"),
			2000,
			$(".global-header").height()
		);
	});

	$(".copy-link").on("click", function (e) {
		e.preventDefault();
		copyURLToClipboard();
		$(".copy-alert").fadeIn();
		setTimeout(() => {
			$(".copy-alert").fadeOut();
		}, 2000);
	});

	$(".btn-call-phone").on("click", function (e) {
		if ($(window).width() > 1024) {
			e.preventDefault();
			$.fancybox.open({
				src: "#popupPhoneCall",
				type: "inline",
				touch: false,
			});
		}
	});

	$(".product-item-title").matchHeight();
	$(".product-item-price").matchHeight();
	$(".news-item-equal-height .news-item-title").matchHeight();
	$(".news-item-equal-height .news-item-brief").matchHeight();
	$(".doctor-item-desc").matchHeight();
	$(".footer-item h3").matchHeight();
	$(".about-section-9 .swiper-slide .content").matchHeight();
	$(".inpatient-detail-match-height").matchHeight();

	setTimeout(() => {
		window.dispatchEvent(new Event("resize"));
	}, 100);

	var $activeLink = $(".manual-nav-wrap li.active");
	if ($activeLink.length) {
		var $container = $(".manual-nav-wrap ul");
		var scrollPosition =
			$activeLink.position().left + $container.scrollLeft();

		// Optional: Adjust if you want to center it
		scrollPosition -= ($container.width() - $activeLink.width()) / 2;

		$container.scrollLeft(scrollPosition);
	}
});

var scrollToElement = function (el, ms, height) {
	var speed = ms ? ms : 2000;
	let scrollHeight = $(el).offset().top - height;
	$("html,body").animate(
		{
			scrollTop: scrollHeight,
		},
		speed
	);
};

function copyURLToClipboard() {
	const textArea = document.createElement("textarea");
	textArea.value = window.location.href;
	document.body.appendChild(textArea);
	textArea.select();
	document.execCommand("copy");
	document.body.removeChild(textArea);
}

function addFirstColumnClass() {
	const row = $(".row-computed");
	let currentTopPosition = null;

	row.find("> *")
		.removeClass("first-column")
		.each(function () {
			const itemTopPosition = $(this).position().top;

			if (
				currentTopPosition === null ||
				itemTopPosition > currentTopPosition
			) {
				currentTopPosition = itemTopPosition;
				$(this).addClass("first-column");
			}
		});
}
