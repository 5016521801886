var $globalHeader = $(".global-header");

jQuery(function () {
	$("body").css({
		"--header-height": $globalHeader.outerHeight() + "px",
	});
});

const externalLinkMapping = new MappingListener({
	selector: ".external-links-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-right-top",
	desktopMethod: "prependTo",
	breakpoint: 1025,
}).watch();

const menuMapping = new MappingListener({
	selector: ".menu-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".search-wrapper",
	desktopMethod: "insertBefore",
	breakpoint: 1025,
}).watch();

const workHourMapping = new MappingListener({
	selector: ".working-hours-wrapper",
	mobileWrapper: ".menu-mobile-body",
	mobileMethod: "appendTo",
	desktopWrapper: ".header-util-wrapper",
	desktopMethod: "insertBefore",
	breakpoint: 1025,
}).watch();

$(".search-toggle").on("click", function (e) {
	e.preventDefault();
	$(this).find("span").toggleClass("fa-search fa-xmark");
	$(".search-container").toggleClass("open").slideToggle();
	$(".searchinput").focus();
	$(".searchinput").val("");
	$(".suggestsearch").remove();
});
$(document).on("click", function (event) {
	if (!$(event.target).closest(".search-toggle, .search-container").length) {
		$(".searchinput").val("");
		$(".suggestsearch").remove();
		$(".search-container").slideUp();
		$(".search-toggle span").removeClass("fa-xmark").addClass("fa-search");
	}
});

// $(".search-backdrop").on("click", function (e) {
// 	e.preventDefault();
// 	$(".search-popup").fadeOut();
// 	$(".search-backdrop").fadeOut();
// 	$(".searchinput").trigger("change");
// 	$(".searchinput").val("");
// 	$("body").removeClass("overflow-hidden");
// });

$(".close-menu, .menu-mobile-backdrop").on("click", function () {
	$(".menu-mobile").removeClass("show");
	$("body").removeClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeOut();
});

$(".menu-toggle").on("click", function () {
	$(".menu-mobile").addClass("show");
	$("body").addClass("overflow-hidden");
	$(".menu-mobile-backdrop").fadeIn();
});

$(".cta-toggle").on("click", function () {
	$(".fixed-cta").toggleClass("show");
	$(this).find("span").toggleClass("fa-ellipsis-vertical fa-xmark");
});

$(".toggle-sub-menu").each(function (index, el) {
	$(el).on("click", function () {
		$(el).next().slideToggle();
	});
});

$globalHeader.sticky({
	zIndex: 51,
	topSpacing: 0,
});
