import scrollToElement from "./ScrollElement";

let activeIndex = $(".nav-item.active").index();
let slideNavSlider = new Swiper(".slidenav .swiper-container", {
	slidesPerView: 4,
	spaceBetween: 8,
	initialSlide: activeIndex,
	navigation: {
		nextEl: ".slidenav .swiper-next",
		prevEl: ".slidenav .swiper-prev",
	},
	breakpoints: {
		1024: {
			slidesPerView: "auto",
		},
	},
});

const landingV1Slider1 = new Swiper(".landing-v1-slider-1 .swiper-container", {
	spaceBetween: 20,
	slidesPerView: 5,
	slidesPerColumn: 2,
	speed: 700,
	pagination: {
		el: ".landing-v1-slider-1 .swiper-pagination",
		clickable: true,
	},
	autoplay: {
		delay: 3000,
	},
	slidesPerColumnFill: "row",
	breakpoints: {
		1024: {
			slidesPerView: 3,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 16,
		},
		576: {
			slidesPerView: 1,
			spaceBetween: 16,
			slidesPerColumn: 1,
		},
	},
});

$(".item-image-caption-slider").each(function (index) {
	const $this = $(this);

	const sliderIndexClass = `${".item-image-caption-slider".slice(
		1
	)}-${index}`;
	$this.addClass(sliderIndexClass);
	$this.find(".swiper-pagination").addClass(`${sliderIndexClass}-pagination`);
	const initSlide = $this.data("slides") || 3;
	const initColumns = $this.data("columns") || 2;
	new Swiper(`.${sliderIndexClass} .swiper-container`, {
		slidesPerView: initSlide,
		slidesPerColumn: initColumns,
		spaceBetween: 32,
		watchSlidesVisibility: true,
		pagination: {
			el: `.${sliderIndexClass}-pagination`,
			clickable: true,
		},
		breakpoints: {
			1024: {
				slidesPerView: 2.5,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 1.5,
				spaceBetween: 16,
			},
			576: {
				slidesPerView: 1.5,
				spaceBetween: 16,
			},
		},
	});
});

const landingV2SliderGallery = new Swiper(
	".landing-v2-gallery-slider .swiper-container",
	{
		spaceBetween: 32,
		navigation: {
			prevEl: ".landing-v2-gallery-slider .swiper-prev",
			nextEl: ".landing-v2-gallery-slider .swiper-next",
		},
	}
);

$(".landing-template-v1-9 .landing-template-v1-sidebar").tabslet({
	container: ".landing-template-v1-9 #tabContainer",
});

$(".landing-template-v1-12 .circle-nav-tab").tabslet({
	container: ".landing-template-v1-12 #tabContainer2",
});

$(".landing-template-v1-13 .landing-template-v1-sidebar").tabslet({
	container: ".landing-template-v1-13 #tabContainer3",
});

var header = $(".global-header");

$(".landing-scroll-nav").sticky({
	topSpacing: header.outerHeight(),
	zIndex: 10,
});

$(".landing-scroll-nav a").on("click", function (e) {
	e.preventDefault();
	scrollToElement(
		$(this).attr("href"),
		1000,
		header.outerHeight() + $(".landing-scroll-nav").height()
	);
});

var spy = new Gumshoe(".landing-scroll-nav a", {
	offset: function () {
		return (
			header.outerHeight() +
			$(".landing-scroll-nav").outerHeight() +
			parseFloat($("section[id*='section-']").css("paddingTop"))
		);
	},
	events: true,
});

document.addEventListener(
	"gumshoeActivate",
	function (event) {
		let index = $(".landing-scroll-nav .swiper-slide").index(
			$(event.target)
		);
		slideNavSlider.slideTo(index);
	},
	false
);
