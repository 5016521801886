const landingThumbnailSlider = new Swiper(
	".landing-thumbnail .swiper-container",
	{
		spaceBetween: 32,
		slidesPerView: 3,
		observer: true,
		observeParents: true,
		slideToClickedSlide: true,
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
		breakpoints: {
			1024: {
				slidesPerView: 3.5,
				spaceBetween: 16,
			},
			768: {
				slidesPerView: 3.5,
				spaceBetween: 8,
			},
		},
	}
);
const landingImageSlider = new Swiper(".landing-images .swiper-container", {
	effect: "fade",
	fadeEffect: { crossFade: true },
	observer: true,
	observeParents: true,
	thumbs: {
		swiper: landingThumbnailSlider,
	},
	navigation: {
		prevEl: ".landing-thumbnail .swiper-prev",
		nextEl: ".landing-thumbnail .swiper-next",
	},
	pagination: {
		el: ".landing-images-wrap .swiper-pagination",
		clickable: true,
	},
	on: {
		slideChange: function () {
			let activeIndex = this.activeIndex + 1;

			let nextSlide = $(
				`.landing-thumbnail .swiper-slide:nth-child(${activeIndex + 1})`
			);
			let prevSlide = $(
				`.landing-thumbnail .swiper-slide:nth-child(${activeIndex - 1})`
			);

			if (nextSlide && !nextSlide.hasClass("swiper-slide-visible")) {
				this.thumbs.swiper.slideNext();
			} else if (
				prevSlide &&
				!prevSlide.hasClass("swiper-slide-visible")
			) {
				this.thumbs.swiper.slidePrev();
			}
		},
	},
});

SVGInject(document.querySelector(".inject-svg"), {
	// useCache: false,
	afterInject: function (img, svg) {
		var panZoomMap = svgPanZoom("#dealerMap", {
			zoomEnabled: true,
			center: true,
			fit: true,
			minZoom: 1,
			beforePan: beforePan,
		});

		$(".zoom-in").on("click", function (ev) {
			ev.preventDefault();
			panZoomMap.zoomIn();
		});
		$(".zoom-out").on("click", function (ev) {
			ev.preventDefault();
			panZoomMap.zoomOut();
		});
		$(".zoom-reset").on("click", function (ev) {
			ev.preventDefault();
			panZoomMap.resetZoom();
			panZoomMap.center();
		});

		mapTippyInit();
	},
});

const mapTippyInit = () => {
	// Select all 'g' elements inside the '.map-mask' element
	let svgGs = $("#dealerMap");
	svgGs.find(".location").each((index, item) => {
		// Store the reference to the current item
		const ref = $(item);
		// Retrieve the content of the tooltip based on the index
		const tooltip = $(`#map-tooltip-${index + 1}`).html();
		// Store the reference to the close button in the tooltip
		const closeBtn = $(tooltip).find(".close");

		ref.attr("id", `location-${index + 1}`);

		// Initialize the tippy tooltips
		tippy(ref[0], {
			content: tooltip,
			allowHTML: true,
			arrow: false,
			placement: $(window).width() > 1024 ? "right" : "top",
			theme: "custom-theme",
			interactive: true,
			trigger: 'mouseenter click',
			popperOptions: {
				strategy: "fixed",
			},
			appendTo: () => document.body,
		});
	});
};

const beforePan = function (oldPan, newPan) {
	var stopHorizontal = false,
		stopVertical = false,
		gutterWidth = $(".dealer-map").outerWidth(),
		gutterHeight = $(".dealer-map").outerHeight(),
		// Computed variables
		sizes = this.getSizes(),
		leftLimit =
			-((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) +
			gutterWidth,
		rightLimit =
			sizes.width - gutterWidth - sizes.viewBox.x * sizes.realZoom,
		topLimit =
			-((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) +
			gutterHeight,
		bottomLimit =
			sizes.height - gutterHeight - sizes.viewBox.y * sizes.realZoom;

	let customPan = {};
	customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
	customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));

	return customPan;
};
