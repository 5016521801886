const productDetailSlider = () => {
    const productDetailThumbnailSlider = new Swiper(
        ".product-detail-thumbnail .swiper-container",
        {
            spaceBetween: 20,
            slidesPerView: 4,
            observer: true,
            observeParents: true,
            slideToClickedSlide: true,
            watchSlidesVisibility: true,
            watchSlidesProgress: true,
            breakpoints: {
                1024: {
                    slidesPerView: 3.5,
                    spaceBetween: 16,
                },
                768: {
                    slidesPerView: 2.5,
                    spaceBetween: 16,
                },
            },
        }
    );
    const productDetailImageSlider = new Swiper(
        ".product-detail-images .swiper-container",
        {
            effect: "fade",
            fadeEffect: { crossFade: true },
            observer: true,
            observeParents: true,
            thumbs: {
                swiper: productDetailThumbnailSlider,
            },
            navigation: {
                prevEl: ".product-detail-thumbnail .swiper-prev",
                nextEl: ".product-detail-thumbnail .swiper-next",
            },
            on: {
                slideChange: function () {
                    let activeIndex = this.activeIndex + 1;

                    let nextSlide = $(
                        `.product-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex + 1
                        })`
                    );
                    let prevSlide = $(
                        `.product-detail-thumbnail .swiper-slide:nth-child(${
                            activeIndex - 1
                        })`
                    );

                    if (
                        nextSlide &&
                        !nextSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slideNext();
                    } else if (
                        prevSlide &&
                        !prevSlide.hasClass("swiper-slide-visible")
                    ) {
                        this.thumbs.swiper.slidePrev();
                    }
                },
            },
        }
    );
};

productDetailSlider();

$(".btn-product-contact").on("click", function(){
	$("#formContactProduct .heading-2").text($(this).data("title"))
})

$(".product-input input").val($(".product-detail-title").text())