$(".product-detail-form-wrap-append").append($(".product-detail-form-wrap"));
$(".news-detail-form-wrap-append").append($(".news-detail-form-wrap"));
$(".landing-template-v1-3 .btn-group").appendTo(
	$(".landing-template-v1-3 .container")
);
$(".faq-form").appendTo($(".faq-form-wrap"));
if ($(".career-list-page").length > 0) {
	$(".col-lg-4 > .career-form-wrap").append($(".career-form"));
	$(".col-lg-4 > .career-form-wrap").append($(".img"));
}
