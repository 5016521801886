function collapseComponent() {
	$(".accordion-item").each(function () {
		let _this = $(this);
		let otherList = _this.siblings();
		let bodyItem = _this.find(".accordion-body");
		let triggerItem = _this.find(".accordion-title");
		let otherBody = $(otherList).find(".accordion-body");

		triggerItem.on("click", function () {
			$(bodyItem).slideToggle();
			// $(otherBody).slideUp();
			_this.toggleClass("active");
			// $(otherList).removeClass("active");
		});
	});
	// $(".accordion-item").eq(0).trigger("click")
}

collapseComponent();
