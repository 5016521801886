const sharedBannerOptions = {
	speed: 1700,
	loop: true,
	autoplay: {
		delay: 4000,
	},
};

const bannerHomeSlider = new Swiper(".home-banner-slider .swiper-container", {
	pagination: {
		el: ".home-banner-slider .swiper-pagination",
		clickable: true,
	},
	speed: 1000,
	loop: true,
	autoplay: {
		delay: 4000,
	},
	cssMode: true,
});

const pressSlider = new Swiper(".press-slider .swiper-container", {
	spaceBetween: 32,
	slidesPerView: 3,
	navigation: {
		prevEl: ".press-slider .swiper-prev",
		nextEl: ".press-slider .swiper-next",
	},
	breakpoints: {
		1024: {
			slidesPerView: 2.5,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 1.5,
			spaceBetween: 16,
		},
		576: {
			slidesPerView: 1.25,
			spaceBetween: 16,
		},
	},
});

const feedbackSlider = new Swiper(".feedback-slider .swiper-container", {
	spaceBetween: 32,
	slidesPerView: 2,
	navigation: {
		prevEl: ".feedback-slider .swiper-prev",
		nextEl: ".feedback-slider .swiper-next",
	},
	breakpoints: {
		1024: {
			slidesPerView: 3.5,
			spaceBetween: 16,
		},
		768: {
			slidesPerView: 2.5,
			spaceBetween: 16,
		},
		576: {
			slidesPerView: 2,
			spaceBetween: 8,
		},
	},
});

const loopSliderInit = (
	sliderClass,
	defaultSlides,
	breakpoints,
	autoplayArg
) => {
	$(sliderClass).each(function (index) {
		const $this = $(this);
		const slideWrapper = $this.find(".swiper-container .swiper-wrapper");
		const slideList = $this.find(".swiper-slide");
		const initSlide = $this.data("slides") || defaultSlides;
		let autoplay;
		if (autoplayArg != undefined) {
			autoplay = {
				...autoplayArg,
			};
		}
		if ($this.hasClass("random-slider")) {
			$(slideList).each(function () {
				$(slideList)
					.eq(Math.floor(Math.random() * $(slideList).length))
					.prependTo($(slideWrapper));
			});
		}

		const sliderIndexClass = `${sliderClass.slice(1)}-${index}`;
		$this.addClass(sliderIndexClass);
		$this.find(".swiper-prev").addClass(`${sliderIndexClass}-prev`);
		$this.find(".swiper-next").addClass(`${sliderIndexClass}-next`);
		$this
			.find(".swiper-pagination")
			.addClass(`${sliderIndexClass}-pagination`);

		new Swiper(`.${sliderIndexClass} .swiper-container`, {
			slidesPerView: initSlide,
			spaceBetween: 32,
			watchSlidesVisibility: true,
			navigation: {
				prevEl: `.${sliderIndexClass}-prev`,
				nextEl: `.${sliderIndexClass}-next`,
			},
			pagination: {
				el: `.${sliderIndexClass}-pagination`,
				clickable: true,
			},
			breakpoints,
			autoplay,
		});
	});
};

loopSliderInit(".product-slider", 3, {
	1024: { slidesPerView: 2.5, spaceBetween: 16 },
	768: { slidesPerView: 1.5, spaceBetween: 16 },
	576: { slidesPerView: 2, spaceBetween: 8 },
});

loopSliderInit(".doctor-slider", 5, {
	1440: { slidesPerView: 4, spaceBetween: 16 },
	1024: { slidesPerView: 3.5, spaceBetween: 16 },
	768: { slidesPerView: 2.5, spaceBetween: 16 },
	576: { slidesPerView: 2, spaceBetween: 8 },
});

loopSliderInit(".news-slider", 4, {
	1024: { slidesPerView: 3.5, spaceBetween: 16 },
	768: { slidesPerView: 2.5, spaceBetween: 16 },
	576: { slidesPerView: 2, spaceBetween: 8 },
});

loopSliderInit(
	".general-slider",
	3,
	{
		1024: { slidesPerView: 3, spaceBetween: 16 },
		768: { slidesPerView: 2, spaceBetween: 16 },
		576: { slidesPerView: 1, spaceBetween: 16 },
	},
	{
		delay: 4000,
	}
);

loopSliderInit(".item-image-slider", 1, {
	1024: { slidesPerView: 3.25, spaceBetween: 16 },
	768: { slidesPerView: 2.25, spaceBetween: 16 },
	576: { slidesPerView: 1.25, spaceBetween: 16 },
});

const brandSlider = new Swiper(".brand-slider .swiper-container", {
	slidesPerView: 9,
	spaceBetween: 26,
	loop: true,
	speed: 3000,
	autoplay: {
		delay: 0,
		disableOnInteraction: false,
	},
	breakpoints: {
		576: {
			spaceBetween: 16,
			slidesPerView: 3,
		},
		768: {
			spaceBetween: 16,
			slidesPerView: 4,
		},
		1024: {
			slidesPerView: 6,
			spaceBetween: 20,
		},
	},
});
