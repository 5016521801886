$(".expand-content").each(function (e) {
	const $this = $(this);
	const contentContainer = $(this).find(".expand-content-container");
	const fixedHeight = $(contentContainer).outerHeight();
	const innerContentHeight = $this
		.find(".expand-content-inner")
		.outerHeight();
	const trigger = $this.find(".expand-content-trigger");
	const mask = $this.find(".expand-content-mask");

	if (fixedHeight >= innerContentHeight) {
		$(trigger).hide();
		$(mask).hide();
	}
	$(trigger).on("click", function (e) {
		e.preventDefault();
		const innerContentHeight = $this
			.find(".expand-content-inner")
			.outerHeight();
		if (!$this.hasClass("show")) {
			$this.addClass("show");
			$(contentContainer).css("max-height", `${innerContentHeight}px`);
		} else {
			$this.removeClass("show");
			$(contentContainer).css("max-height", `${fixedHeight}px`);
		}
		$(this).find("span").toggleHtml(`Xem thêm`, `Rút gọn`);
		$(this).find("em").toggleClass("fa-rotate-180");
	});
});

$.fn.extend({
	toggleHtml: function (a, b) {
		return this.html(this.html() == b ? a : b);
	},
});
